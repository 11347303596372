import * as React from "react";
import { Seo, Header, Faq, ProductsBanner } from "components";
import { Section, Container } from "styles";
import { graphql } from "gatsby";

const FAQPage = ({ data }) => {
  const { faqs } = data.markdownRemark.frontmatter;
  return (
    <>
      <Seo title="Frequently asked questions about ThermaCare®" />
      <Header
        data={data}
        title="Frequently asked questions<br /> about ThermaCare®"
        content="<p>Here you will find a list of the most frequently asked questions about ThermaCare®<br /> products, how they work and how they are used.</p>"
      />
      <Section>
        <Container style={{ gridGap: ".25rem" }}>
          <Faq faqs={faqs} />
        </Container>
      </Section>
      <Section>
        <ProductsBanner />
      </Section>
    </>
  );
};

export default FAQPage;

export const query = graphql`
  query FAQQuery {
    markdownRemark(frontmatter: { title: { eq: "Faqs" } }) {
      frontmatter {
        title
        faqs {
          question
          answer
        }
      }
    }
    topFlame: file(relativePath: { eq: "background-top-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
    bottomFlame: file(relativePath: { eq: "background-bottom-flame.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;
